/*import necessary fonts */
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


/* import all tailwind css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* commons */
:root {
    font-size: 16px;
}
html{
    scroll-behavior: smooth;
}
body{
    font-family: Lexend;
}

/* Font sizes for title */
/* 82px */
.text-title-huge{
    font-size: 5rem;
}
/* 56px */
.text-title-large{
    font-size: 3.5rem;
}
/* 50px */
.text-title-medium{
    font-size: 3rem;
}
/* 32px, 30px */
.text-title-normal{
    font-size: 2rem;
}
/* 24px */
.text-title-small{
    font-size: 1.5rem;
}


/* Font size for paragraphs */
/* 20px */
.text-para-huge{
    font-size: 1.25rem;
}
/* 18px */
.text-para-large{
    font-size: 1.125rem;
}
/* 16px */
.text-para-medium{
    font-size: 1rem;
}
/* 14px,13px */
.text-para-normal{
    font-size:0.875rem;
}
/* 8px */
.text-para-small{
    font-size: 0.625rem;
}


/* specifics */
.cursorFollowerFirst {
    position: fixed;
    width: 100rem;
    height: 100rem;
    background: 
        radial-gradient(circle at 45% 45%, #fff570 0px, transparent 40%);
    transform: translate(-50%, -50%);
    transition: transform 0.3s;
    mix-blend-mode: normal;
    z-index: -999;
    pointer-events: none;
}
.cursorFollowerSecond {
    position: fixed;
    width: 100rem;
    height: 100rem;
    background: 
        radial-gradient(circle at 60% 60%, #7bcabe 0px, transparent 40%);
    transform: translate(-50%, -50%);
    transition: transform 0.3s;
    mix-blend-mode: normal;
    z-index: -999;
    pointer-events: none;
}
.cursorFollowerThird {
    position: fixed;
    width: 100rem;
    height: 100rem;
    background: 
        radial-gradient(circle at 40% 48%, #f7d9de 0px, transparent 40%);
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transition: transform 0.3s;
    -webkit-transform: transform 0.3s;
    mix-blend-mode: normal;
    z-index: -999;
    pointer-events: none;
}
#banner-section{
    top: 90px;
}
.banner-area{
    height: 100vh;
    min-height: 800px;
}
.banner-button-img{
    width: 36px;
    height: auto;
}
#header-section{
    padding: 40px 0;
}
#about-us-section{
    margin-bottom: 60px;
    padding-top: 20px;
}
/* Services section */
.services-area{
    padding-top: 20px;
    margin-bottom: 100px;
    margin-left:auto;
    margin-right:auto;
}
.our-work{
    padding-top: 20px;
    margin-bottom: 60px;
}
#whatwedo-slider {
	display: flex;
	position: relative;
	transition: left 0.5s;
}
.info-card{
    background-color: white;
    padding:3rem 2rem;
    border-radius: 30px;
    min-width: 345px;
    margin-right: 30px;
}
.info-card:last-child{
	margin-right: 0 !important;
}
.info-card-image{
    padding: 30px;
    border-radius: 30px;
}
.info-card-icon{
    width: 33px;
    height: auto;
}
.reasons-to-choose-us{
    padding-top: 20px;
    margin-bottom: 20px;
}
.reasons-to-choose-us-title{
    padding-bottom: 60px;
    padding-top: 60px;
}
.reasons-to-choose-us-body{
    gap:100px;
}
.width-controlled{
    max-width: 558px;
    line-height: 2.75rem;
}
/* Portfolio section */
#portfolio-section{
	padding-top: 20px;
	margin-bottom: 100px;
}
.portfolio-gallery{
	width: 100%;
	column-gap: 70px;
	row-gap: 0px;
}
.portfolio-card{
	margin-bottom: 70px;
}
/*Hire us section */
.why-not-hire-us-section{
    margin-bottom:60px;
}
#hire-slider{
    --d: 20s; /* duration */
    overflow: hidden;
    /* --card-count: 4; Is now set by JS */ 
}
.gradient-hire-us{
    mask: linear-gradient(90deg,#0000,#000 4% 96%,#0000);
}
#hire-slider-inner  {
    display: flex;
    flex-direction: row;
    padding-block: 1rem;
    width: max-content;
}
.animate-hire-us{
    animation: scroll var(--d) linear infinite;
}
@keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-340px * var(--card-count)))}
}
.poster-card-inner{
    left: 40px;
    top: 340px;
}
.poster-card {
    margin-right: 40px;
}

  
/* Our Team section */
#our-team-section{
	padding-top: 20px;
	margin-bottom: 90px;
}
#team-gallery{
    /* gap:40px; */
}
#ourteam-slider {
	display: flex;
	position: relative;
	transition: left 0.5s;
}
.team-slider-inner{
    min-width: 345px;
    margin-right: 35px;
}
.team-slider-inner:last-child{
    margin-right: 0px;
}
.teams-card{
    margin-bottom:35px;
}
.subscribe{
	padding: 8px;
	margin-bottom: 100px;
	border-radius: 16px;
}
.subscribe-image{
	width: 150px;
	height: 150px;
	margin-right: 20px;
}
/* Testimonial */
#testimonial-section{
	padding-top: 20px;
	margin-bottom: 100px;
}
#testimonial-slider {
	display: flex;
	position: relative;
	transition: left 0.5s;
	padding-left:5px;
	padding-right:5px;
	margin-bottom: 16px;
}
.testimonial_card{
    background-color: white;
	min-width: 500px;
	margin-right: 30px;
	border-radius: 40px;
}
.testimonial_card:last-child{
	margin-right: 0 !important;
}
/* Get in touch section */
#get-in-touch-section{
	margin-bottom: 60px;
    padding-top: 50px;
    padding-bottom: 50px;
}
.inputdiv{
    height: 60px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    background-color: #ffffff99;
    border-width: 1px;
    border-color: #e0e0e0;
    font-family: Montserrat;
}
.find-us-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url('data:image/svg+xml,<svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 6L12 0L0 -5.24537e-07L6 6Z" fill="black"/></svg>') no-repeat;
    background-position: right 15px top 50%;
}

/* Media Queries */
/* Small Device */
@media screen and (max-width: 768px) {
    /* Commons */
    .text-title-huge{
        font-size: 2.25rem;
    }
    .text-title-large{
        font-size: 2rem;
    }
    .text-title-normal{
        font-size: 1.5rem;
    }
    .text-title-small{
        font-size: 1rem;
    }
    .text-para-huge{
        font-size: 1rem;
    }
    .text-title-medium{
        font-size: 2rem;
    }
    .text-para-medium{
        font-size: 0.75rem;
    }
    .width-section{
		margin-left: 20px;
		margin-right: 20px;
	}

    /* Specifics */
    .banner-area{
        height: 80vh;
    }
    .banner-button-img{
        width: 24px;
        height: auto;
    }
	.portfolio-gallery{
		width: 100%;
		column-gap: 35px;
	}
    .testimonial_card{
		min-width: 80vw !important;
	}
    .map{
        height: 450px;
    }
    .team-slider-inner{
        min-width: 150px;
        margin-right: 35px;
    }
}

/* Medium Device */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    /* Commons */
    .text-title-huge{
        font-size: 3.5rem;
    }
    .text-title-large{
        font-size: 2.125rem;
    }
    .text-title-normal{
        font-size: 1.5rem;
    }
    .text-title-small{
        font-size: 1rem;
    }
    .text-title-medium{
        font-size: 2rem;
    }
    .text-para-huge{
        font-size: 1.125rem;
    }
    .text-para-medium{
        font-size: 0.75rem;
    }
    .width-section{
		margin-left: 40px;
		margin-right: 40px;
	}

    /* Specifics */
    .banner-button-img{
        width: 24px;
        height: auto;
    }
	.portfolio-gallery{
		width: 100%;
		column-gap: 35px;
	}
	.portfolio-card{
		margin-bottom: 40px;
	}
    .map{
        height: 450px;
    }
}

/* Large Device */
@media screen and (min-width: 1024px) {
    /* Commons */
    .width-section{
		margin-left: 70px;
		margin-right: 70px;
	}

    /* Specifics */
    .subscribe{
		margin-left: 100px !important;
		margin-right: 100px !important;
	}
    .gradient-background-fifth{
		background: linear-gradient(to right, #ffffff00 70%, #008c82 30%);
	}
    .map{
        width: 50%;
    }
    
}